

















import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import AiLoadingOverlay from '@/tasks/components/AiLoadingOverlay.vue';

export default {
  name: 'OleMissL4PreLabPart3',
  components: {AiLoadingOverlay, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  props: {
    isSubmitting: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inputs: {
        part4_13: null,
      },
      questions: [
        {
          text: 'You discover that a particular iron alloy is being used in the production of pickup truck frames. What physical AND chemical properties would you look for in a substance in order to protect the frame of the pickup truck from rust?',
          inputName: 'part4_13',
        },
      ],
    };
  },
};
